import { getRequest, putRequest } from "../api";

const path = "admin/ext-billing/invoice/redeem";

export const getAllRedeem = async (page) => {
  if (isNaN(page)) {
    page = 0;
  }

  page = isNaN(page) ? 0 : page - 1;
  if (page < 0) page = 0;

  try {
    const response = await getRequest(`${path}?page=${page}&pageSize=50`, true);
    return response;
  } catch (err) {
    throw err;
  }
};

export const approveRedeem = async (id) => {
  try {
    const response = await putRequest(`${path}/${id}/approve`, {}, true);
    return response;
  } catch (err) {
    throw err;
  }
};
