import React, { useState, useEffect } from "react";
import {
  approveRedeem,
  getAllRedeem,
} from "../../../services/billing-invoice/redeem";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import SectionHeader from "../../../components/section/SectionHeader";
import SectionTitle from "../../../components/section/SectionTitle";
import InvoiceRedeemTable from "../../../components/billing-invoice-redeem/InvoiceRedeemTable";
import PaginatedView from "../../../components/pagination/PaginatedView";

import { paginationOffset } from "../../../helpers/pagination";

function InvoiceRedeem() {
  const [redeems, setRedeems] = useState([]);
  const [redeemResponse, setRedeemResponse] = useState(null);

  const { page } = useParams();

  async function fetchRedeems() {
    try {
      const response = await getAllRedeem(page);
      setRedeemResponse(response);
      setRedeems(response.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchRedeems();
  }, [page]);

  const handleToggleApprove = async (id) => {
    try {
      const response = await approveRedeem(id);
      if (response) {
        fetchRedeems();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white p-2">
        <div className="rounded-t mb-0 px-4 py-3 pb-8 border-0">
          <SectionHeader>
            <SectionTitle title={"Bonus Redeems"} />
          </SectionHeader>
        </div>

        <InvoiceRedeemTable
          offset={paginationOffset(redeemResponse?.metadata)}
          data={redeems}
          onToggleApprove={handleToggleApprove}
        />

        {redeemResponse?.metadata?.totalPages > 1 ? (
          <div className="py-2 flex justify-end my-6">
            <PaginatedView
              path={`/invoice/redeem/page`}
              total={redeemResponse?.metadata.totalPages}
              current={redeemResponse?.metadata?.currentPage}
              next={redeemResponse?.metadata?.nextPage}
              previous={redeemResponse?.metadata?.previousPage}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}

export default InvoiceRedeem;
