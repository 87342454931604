import React from "react";
import { formatAppDate } from "../../helpers/date-formatter";
import { Link } from "react-router-dom";
import { EyeIcon } from "@heroicons/react/24/outline";

function InvoiceRedeemTable({ offset, data, onToggleApprove }) {
  return (
    <>
      <table className="w-full min-w-[640px] table-auto">
        <thead>
          <tr>
            {[
              "sn",
              "user",
              "amount (€)",
              "code",
              "redeem date",
              "approved",
              "approved date",
            ].map((el, id) => (
              <th
                key={el}
                className={`border-y bg-slate-50 border-slate-50 py-3 px-5 ${
                  id < 2 ? "text-left" : "text-center"
                }`}
              >
                <span className="text-[11px] font-bold uppercase text-slate-400">
                  {el}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((dataItem, key) => {
            const {
              id,
              amount,
              approved,
              approvedDate,
              code,
              createdOn,
              fullname,
              userId,
              username,
            } = dataItem;

            return (
              <tr key={id}>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[100px]">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {offset && !offset.isNaN ? offset + key + 1 : key + 1}
                  </span>
                </td>
                <td className="py-3 px-5 border-b border-blue-gray-50 w-[100px]">
                  <div className="flex flex-col gap-2">
                    <span className="text-left text-xs font-semibold text-blue-gray-600">
                      {fullname}
                    </span>
                    <span className="text-left text-xs text-blue-gray-400 underline hover:text-blue-500">
                      <Link to={`/users/${userId}`}>{username}</Link>
                    </span>
                  </div>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    €{amount}
                  </span>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {code}
                  </span>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {createdOn !== null ? formatAppDate(createdOn) : "-"}
                  </span>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span
                    className={`text-xs font-semibold py-1 px-3 rounded-md  ${
                      approved
                        ? "text-blue-500 bg-blue-100"
                        : "text-amber-500 bg-amber-100 cursor-pointer"
                    }`}
                    title={approved ? "Approved" : "Click to approve"}
                    onClick={() => {
                      if (!approved) {
                        onToggleApprove(id);
                      }
                    }}
                  >
                    {approved ? "Approved" : "Pending"}
                  </span>
                </td>
                <td className="text-center py-3 px-5 border-b border-blue-gray-50 w-32">
                  <span className="text-xs font-semibold text-blue-gray-600">
                    {approvedDate !== null ? formatAppDate(approvedDate) : "-"}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default InvoiceRedeemTable;
